import {
  BLAINE_WAREHOUSE,
  CDEK_DELIVERY_SERVICE,
  CITY_KIEV,
  CITY_LVIV,
  CITY_MINSK,
  CURRENCIES,
  DHL_DELIVERY_SERVICE,
  DHLEXPRESS_DELIVERY_SERVICE,
  EXPRESS_DELIVERY_STATUSES,
  EXPRESS_MAKE_LABEL_STATUSES,
  FEDEX_DELIVERY_SERVICE,
  INTERNAL_STATUSES,
  LODZ_WAREHOUSE,
  NOVAPOSHTA_DELIVERY_SERVICE,
  NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
  PROFORM_TYPE_NAMING,
  PUERTO_RICO_REGION,
  PYRZOWICE_WAREHOUSE,
  TNT_DELIVERY_SERVICE,
  UKRAINIAN_COUNTRY_ID, UNITED_KINGDOM_ID,
  USA_COUNTRY_ID
} from "../../staticData/staticVariables";
import {expressUserContactsMixin} from "@/mixins/expressMixins/expressUserContactsMixin";

const ServiceTypesExpress = {
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE,
  NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
}

export const expressMixins = {
  computed: {

    getProformDocType() {
      switch (this.ex.express.type) {
        case DHL_DELIVERY_SERVICE.id :
          return 'orderDHL'
        case FEDEX_DELIVERY_SERVICE.id :
          return 'orderFEDEX'
        case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id :
          return 'orderNPG'
      }
    },

    calculatePrice() {
      return this.$options.filters.number(this.ex.getTotalSumProduct(), '0.00')
    },

    isDHLNoTrn: function() {
      if(this._.has(this.$store.getters.getUserProfile, 'id'))
        return parseInt(this.$store.getters.getUserProfile.user_setting.dhl_no_transaction) === 1
      return false
    },

    isTNTNoTrn: function() {
      if(this._.has(this.$store.getters.getUserProfile, 'id'))
        return parseInt(this.$store.getters.getUserProfile.user_setting.tnt_no_transaction) === 1
      return false
    },

    isFedexNoTrn: function() {
      if(this._.has(this.$store.getters.getUserProfile, 'id'))
        return parseInt(this.$store.getters.getUserProfile.user_setting.fedex_no_transaction) === 1
      return false
    },

    checkDisabledSenderFedex() {
      let countryGroup = this.getUserContactDataFromUser(this.ex.express.user)?.country_group
      if(this.isUkrainianByUserData(countryGroup) && !this.getUserAlternativeContact(this.ex.express.user))
        return true
      if(this.isUkrainianByUserData(countryGroup) && this.getUserAlternativeContact(this.ex.express.user))
        return false

      return !this.isFedexNoTrn && this.isCurrentUserRoleUser && !this.hasTransaction(this.ex.express)
    },

    isWarehousePyrzowice() {
      return this.ex.express.warehouse?.id === PYRZOWICE_WAREHOUSE.id
    },

    isWarehouseLodz() {
      return this.ex.express.warehouse?.id === LODZ_WAREHOUSE.id
    },
  },

  methods: {

    changeSavedProformExpress(val) {
      this.ex.express.toxicSubstanceControlActCert = val?.need_tsca
    },

    changeProformSelectExpress() {
      let needTSCA = false
      this.ex.express.products.map(proform => {
        console.log(proform.product);
        if(proform.product.proformData?.proformTypes['2']) {
          let material = proform.product.proformData?.proformTypes['2']

          if(material?.typeValue?.need_tsca) {
            needTSCA = true
          }
        }
      })
      this.ex.express.toxicSubstanceControlActCert = needTSCA
    },

    manipulateWarehouses(edit = false) {
      if (this.isEAEUGroup && !this.isBelarus) {
        this._.remove(this.warehouseOptions, function (n) {
          return n.id === CITY_MINSK.id
        })
      }

      if (this.ex.express.type === DHLEXPRESS_DELIVERY_SERVICE.id) {
        let lviv = this._.find(this.warehouseOptions, {id: CITY_LVIV.id})
        let kiev = this._.find(this.warehouseOptions, {id: CITY_KIEV.id})
        let pyrzowice = this._.find(this.warehouseOptions, {id: PYRZOWICE_WAREHOUSE.id})

        if (lviv) {
          this.warehouseOptions = [lviv]
          if (kiev) this.warehouseOptions.push(kiev)
          if (pyrzowice) this.warehouseOptions.push(pyrzowice)
        }

        // set warehouse if create and options more then zero
        if (!edit && this.warehouseOptions.length > 0 && !this.ex.express.warehouse?.id) this.changeWarehouse(this.warehouseOptions[0])

        return true
      }

      return false
    },

    setExpressRegion(reg) {
      let regions = this._.clone(reg)
      let PR = this._.findIndex(regions, {id: PUERTO_RICO_REGION.id})
      if(PR >= 0) {
        regions.splice(PR, 1)
      }
      this.ex.setRegions(this._.toArray(regions))
    },

    isInternalNovaPoshta(item) {
      return (item && item.order_internal.delivery_service_id === NOVAPOSHTA_DELIVERY_SERVICE.id)
    },

    isInternalCDEK(item) {
      return (item && item.order_internal.delivery_service_id === CDEK_DELIVERY_SERVICE.id)
    },

    isInternalLabelCreated(item) {
      return (item && INTERNAL_STATUSES.COMPLETE.id === item.order_internal_status_id)
    },

    isInternalDraft(item) {
      return (item && INTERNAL_STATUSES.IN_PROGRESS.id === item.order_internal_status_id && !item.tracking_number)
    },

    isNewPostTrackingNum(item) {
      return (item.new_post_tracking_number)
    },

    canCreateOrderInternal(item) {
      return (!item.order_internal)
    },

    canCallCourier(item) {
      return ((!item.pickup_data && !item.cancel_pickup_data) || (!item.pickup_data))
    },

    canCancelCourier(item) {
      return (!item.cancel_pickup_data && item.pickup_data)
    },

    isDHLService(id){
      return id === DHL_DELIVERY_SERVICE.id
    },

    isWaitForPayStatus(status){
      return status === EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS
    },

    waitingMakingLabelStatus(status){
      return status === EXPRESS_DELIVERY_STATUSES.WAIT_FOR_MAKING_LABEL_STATUS
    },

    hasTransaction(item){
      return item.issetPaymentTransaction
    },

    isCompletedStatus(status){
      return status === EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS
    },

    isCopyStatus(status){
      return status === EXPRESS_DELIVERY_STATUSES.COPY_STATUS
    },

    isTNTService(id){
      return id === TNT_DELIVERY_SERVICE.id
    },

    isFedexService(id){
      return id === FEDEX_DELIVERY_SERVICE.id
    },

    isAPILabelData(item){
      return !item.label_data
    },

    getCustomerReferenceType(ref) {
      if(ref.indexOf('coof') > -1){
        return {
          type: 'O',
          value: this.$t('express_originCertificate.localization_value.value'),
        }
      }
      if(ref.indexOf('invoice') > -1){
        return {
          type: 'P',
          value: this.$t('express_originProforma.localization_value.value'),
        }
      }
      if(ref.indexOf('dbts') > -1){
        return {
          type: 'B',
          value: this.$t('express_dropBallCertificate.localization_value.value'),
        }
      }
      if(ref.indexOf('tsca') > -1){
        return {
          type: 'T',
          value: this.$t('express_toxicCertificate.localization_value.value'),
        }
      }
    },

    confirmLabel(id, deliveryServiceId, fromCard = false) {

      let orderExpressType = this.getExpressTypeById(deliveryServiceId)

      let text = {
        title: 'common_ConfirmLabel',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        if(fromCard) {
          this.updateExpress({draft: false, createLabel: false}, true).then(() => {
            this.confirmLabelFunc(id, orderExpressType, fromCard)
          })
        } else {
          this.confirmLabelFunc(id, orderExpressType, fromCard)
          this.$emit('close')
        }

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },

    confirmLabelFunc(id, orderExpressType, fromCard) {
      this.$store.dispatch('adminConfirmMakeLabel', {
        type: orderExpressType.name,
        dataPayload: {
          order_express_id: id
        }
      }).then((response) => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationStatusChanged')
            if(fromCard) {
              this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
              return
            }

            this.$emit('reload')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    removeExpress(id, index = false) {
      let text = {
        title: 'importO_deleteOrder',
        txt: 'importO_deleteOrderQuest',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }


      let confirm = () => {

        this.$store.dispatch('deleteExpress', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordDeleted')

            this.fixShowManageBar = false

            if(index !== false){
              this.$store.getters.getExpress.splice(index, 1)
            } else {
              this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            }

            setTimeout(() => {
              this.fixShowManageBar = true
            }, 1)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    copyExpress(id, table = false) {
      this.$store.dispatch('copyExpress', {order_express_id: id}).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordCreated')
            if(!table){
              this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
            } else {
              this.$emit('reload')
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    getExpressTypeById(id) {
      return this._.find(ServiceTypesExpress, {id: parseInt(id)})
    },

    getLabelPriceFromTable(expressItem) {
      let deliveryType = this.getExpressTypeById(expressItem.expressOrderInstance.delivery_service_id),
        data = this.generateCreateLabelDataFromTable(deliveryType, expressItem)

      // if(deliveryType.id === DHL_DELIVERY_SERVICE.id){
      this.getCostExpressLabel(data, deliveryType.name)
      // }
    },

    generateCreateLabelDataFromTable(deliveryType, expressItem) {

      let express = expressItem.expressOrderInstance

      let senderData = {
        senderContactName: express.sender_contact_name,
        senderPhone: express.sender_phone_number,
      }

      let warehouse = null
      if(express.warehouse){
        warehouse = express.warehouse
      }

      let products = []
      express.proform_entities.map(item => {
        let name = this._.find(item.proform_entity_values ,function (itemSearch) {
          return itemSearch?.proform_attachment?.proform_type_id === PROFORM_TYPE_NAMING
        })
        if(!name) name = 'product'

        products.push({
          name: name?.proform_attachment?.proform_value?.translationStorage?.en?.name,
          count: item.item_quantity,
          price: item.item_price,
        })
      })

      let data = {}

      switch (deliveryType.id) {
        case DHL_DELIVERY_SERVICE.id:
          data = {
            'warehouse_id': warehouse ? warehouse.id : null,
            "order_express_id": express.id,
            "sender_contact_name": warehouse ? warehouse.sender_name : senderData.senderContactName,
            "sender_phone_number" : warehouse ? warehouse.sender_phone : senderData.senderPhone,
            "recipient_address" : express.recipient_address,
            "recipient_city" :  express.recipient_city,
            "recipient_region" :  express?.recipient_region,
            "recipient_zip_code" : express.recipient_zip_code,
            "recipient_country_id" : express.recipient_country.id,
            "products" : products,
            "proform_packaging" : {
              "weight": express.proform_packaging.weight,
              "length": express.proform_packaging['length'],
              "width": express.proform_packaging.width,
              "height": express.proform_packaging.height,
            },
            "delivery_date" : this.$moment(express.delivery_date).format('YYYY-MM-DD'),
            "insurance_amount" : express.insurance_amount
          }
          break

        case TNT_DELIVERY_SERVICE.id:
          data = {
            "user_id": express.user_id,
            "sender": express.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "city": express.warehouse.sender_city,
                "post_code": express.warehouse.sender_postcode
              } :
              {
                "country_code": express.user.contacts[0].country.code_iso_2,
                "city": express.user.contacts[0].city,
                "post_code": express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": express.recipient_country.code_iso_2,
              "city": express.recipient_city,
              "post_code": express.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": express.proform_packaging.weight,
              "length": express.proform_packaging['length'],
              "width": express.proform_packaging.width,
              "height": express.proform_packaging.height,
            },
            "insurance_amount": express.insurance_amount
          }
          break


        case FEDEX_DELIVERY_SERVICE.id:
          data = {
            'warehouse_id': warehouse ? warehouse.id : null,
            "user_id": express.user_id,
            "sender": express.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "post_code": express.warehouse.sender_postcode
              } :
              {
                "country_code": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).country?.code_iso_2 : express.user.contacts[0].country.code_iso_2,
                "city": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).city : express.user.contacts[0].city,
                "post_code": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).zip : express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": express.recipient_country.code_iso_2,
              "post_code": express.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": express.proform_packaging.weight,
              "length": express.proform_packaging['length'],
              "width": express.proform_packaging.width,
              "height": express.proform_packaging.height,
              "packaging_type": express.packaging_type,
            },
            "insurance_amount": express.insurance_amount,
          }
          break

        case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id:
          data = {
            'warehouse_id': warehouse ? warehouse.id : null,
            "user_id": express.user_id,
            "sender": express.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "post_code": express.warehouse.sender_postcode
              } :
              {
                "country_code": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).country?.code_iso_2 : express.user.contacts[0].country.code_iso_2,
                "city": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).city : express.user.contacts[0].city,
                "post_code": this.getUserAlternativeContact(express.user) ? this.getUserAlternativeContact(express.user).zip : express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": express.recipient_country.code_iso_2,
              "post_code": express.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": express.proform_packaging.weight,
              "length": express.proform_packaging['length'],
              "width": express.proform_packaging.width,
              "height": express.proform_packaging.height,
              "packaging_type": express.packaging_type,
            },
            "insurance_amount": express.insurance_amount,
          }
          break
      }

      if(express.payment_transaction_id) {
        data['payment_transaction_id'] = express.payment_transaction_id
      }

      data['order_express_id'] = express.id

      return data
    },

    getCostExpressLabel(data, deliveryType) {

      if(deliveryType === ServiceTypesExpress.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name && this.ex.express.visibleVatNumber && this.ex.express.vatNumber !== '') {
        data['vat_number'] = this.ex.express.vatNumber
      }

      this.$store.dispatch('getShippingCost', {data: data, type: deliveryType}).then((response) => {
        if (this._.has(response, 'data') &&
          response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.labelPrice = this._.cloneDeep(response.data.data)

          // fix if only variantLabelPrice -> set first variant as main labelPrice
          // let firstGetPriceWithOnlyVariants = false
          if(!this.labelPrice?.total_price && this.labelPrice?.price_variant.length > 0) {
            let priceVariant = this.labelPrice?.price_variant
            this.labelPrice = this.labelPrice?.price_variant[0]
            priceVariant.splice(0, 1)
            this.labelPrice.price_variant = priceVariant
            if(this.ex.express.variantLabelPrice === null) {
              this.ex.express.variantLabelPrice = this.labelPrice.delivery_service_type
            } else if(response.data.data?.price_variant.length === 1) {
              this.ex.express.variantLabelPrice = response.data.data?.price_variant[0].delivery_service_type
            }
          }


          // fix for variantLabelPrice
          this.labelPrice.old_total_price = this.labelPrice.total_price
          if(this.ex.express.variantLabelPrice !== null && this._.find(this.labelPrice.price_variant, {delivery_service_type: this.ex.express.variantLabelPrice})) {
            this.labelPrice.total_price = this._.find(this.labelPrice.price_variant, {delivery_service_type: this.ex.express.variantLabelPrice})?.total_price
          }


        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors

          if(Object.keys(errors).length > 0){
            this.openNotify('error', {txtServer: this._.first(errors[this._.first(Object.keys(errors))]) })
          }

          // alert('novalid')
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    changeVariant(val) {
      this.ex.express.variantLabelPrice = val.delivery_service_type

      if(this.ex.express.variantLabelPrice !== null && val.total_price) {
        // this.labelPrice.old_total_price = this.labelPrice.total_price
        this.labelPrice.total_price = val.total_price //Number(this.labelPrice.total_price) - Number(this.labelPrice.price) + Number(val.price)
      } else {
        this.labelPrice.total_price = this.labelPrice.old_total_price
      }
    }

  }
}

export const expressMixinsFromCard = {

  mixins: [expressMixins],

  computed: {
    checkBalticGroup() {
      let userContacts = this.getUserContactDataFromUser(this.ex.express.user)
      if(this.getUserAlternativeContact(this.ex.express.user)) {
        userContacts = this.getUserAlternativeContact(this.ex.express.user)
      }
      return this.isBalticByUserData(userContacts?.country_group)
    },

    checkFedexPackaging() {
      let userContacts = this.getUserContactDataFromUser(this.ex.express.user)
      if(this.getUserAlternativeContact(this.ex.express.user)) {
        userContacts = this.getUserAlternativeContact(this.ex.express.user)
      }

      return this.isUkraineCountryByUserData(userContacts?.country_id) || this.isPolandCountryByUserData(userContacts?.country_id)
    },

    isEasyType() {
      return this.ex.express.easyOrderType
    }
  },

  methods: {

    updateExpress({draft, createLabel = false}, fromConfirm = false, upsert = false) {
      console.log(369369);
      console.log(upsert);
      if(this.$store.getters.getIsAdminRights === 'admin') {
        if(!this.checkAdminValidation()) return
      }

      let data = this.ex.prepareCreation(
        draft,
        this.$store.getters.getUserProfile.id,
        true
      )

      let expressId = this.$route.params.id

      let saveType = 'updateExpress'

      if(this.ex.express.easyOrderType) {
        saveType = 'updateEasyOrdersExpress'
      }

      return this.$store.dispatch(saveType, {id: expressId, data: data}).then(response => {
        if (this._.has(response, 'data') &&
          response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          if(fromConfirm) {
            return
          }

          if(upsert && this.ex.express.changedAdminFields && this.payedInvoiceNetAmount !== '' && this.payedInvoiceFeeAmount !== '') {
            this.upsertExpress(expressId)
            return
          }

          let expressType = this.getExpressTypeById(data.delivery_service_id)

          if (createLabel && (this.checkNewFiles() || this.ex.express.vatNumberFile.getFiles().length > 0)) {
            this.createFiles(expressId).then(() => {
              this.makeLabelHandler(expressId, expressType).then((status) => {
                if(this._.has(status, 'statusError')){
                  return
                }
                if(!this._.has(status, 'statusPaid')){
                  this.goToTable()
                }
              }).catch(error => this.createErrorLog(error))
            }).catch(error => this.createErrorLog(error))
            return
          }

          if (createLabel) {
            this.makeLabelHandler(expressId, expressType).then((status) => {
              if(this._.has(status, 'statusError')){
                return
              }
              if(!this._.has(status, 'statusPaid')){
                this.goToTable()
              }
            })
            return
          }

          if( this.checkNewFiles() || this.ex.express.vatNumberFile.getFiles().length > 0) {
            this.createFiles(expressId).then(() => {
              this.goToTable()
            }).catch(error => this.createErrorLog(error))
            return
          }

          this.goToTable()

          this.openNotify('success', 'common_notificationRecordChanged')

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }

      }).catch(error => this.createErrorLog(error))
    },

    goToTable() {
      /**
       * if Easy order
       */
      if(this.isEasyType) {
        this.$router.push(this.$store.getters.GET_PATHS.easyOrders)
        return
      }

      this.$router.push(this.$store.getters.GET_PATHS.expressOrders + this.$store.getters.getExpressFilter)
    },

    checkPermission(){
      return this.checkOrderTypePermission({
        types: {
          [ServiceTypesExpress.DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
          [ServiceTypesExpress.FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
          [ServiceTypesExpress.TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
          [ServiceTypesExpress.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_NPG,
        },
        currentType: this.$route.params.type
      })
    },

    checkAdminValidation(){
      let valid = true
      if(!this.ex.firstValidationUser()) valid = false
      if(!this.ex.firstValid()) valid = false
      this.ex.express.products.map(item => {
        if(!item.productProformValidation({
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          HSCode: this.ex.getVisibleHSCode(),
          proformMaterial: true,
        })) valid = false
      })
      if(!(
        this.ex.validateOrderPrice() &&
        this.ex.express.personal.personalInfoValidate() &&
        this.ex.express.delivery.deliveryInfoPartialValidate({
          address: true,
          address2: true,
          city: true,
          region: true,
          country: true,
          zip: true,
          latin: false,
          onlyLatinAddress: true,
        }) &&
        this.validateFranceZip() &&
        // this.ex.express.delivery.deliveryAddressExcludedValidate() &&
        this.ex.deliveryDateValid() &&
        this.ex.freightCostValidate() &&
        this.ex.vatNumberValidation() //&&
        //this.checkValidExpressFiles()
      )
      ) valid = false
      if(!this.ex.express.packaging.packagingValidate()) valid = false
      if(!this.ex.insuranceValueValidate()) valid = false

      return valid
    },

    validateFranceZip() {
      if(this.ex.express.delivery.getCountry()?.id === 74 && this.ex.express.delivery.getZip() + '' === '98000') {
        this.openNotify('error', 'common_notCountryWithZip')
        return false
      }
      return true
    },

    getLabelPriceFromCard() {
      if(this.$store.getters.getIsAdminRights === 'admin'){
        if(!this.checkAdminValidation()) return
      }

      let deliveryType = this.getExpressTypeById(this.ex.express.type),
        data = this.generateCreateLabelDataFromCard(deliveryType)

      // if(deliveryType.id === DHL_DELIVERY_SERVICE.id){
        this.getCostExpressLabel(data, deliveryType.name)
      // }
    },

    generateCreateLabelDataFromCard(deliveryType) {
      let express = this.ex.express
      let delivery = express.delivery.delivery

      let senderData = {
        senderContactName: express.sendType === express.sendTypes.sender ?
          express.senderContactName : this.$store.getters.getUserProfile.user_personal_contact.user_full_name,
        senderPhone: express.sendType === express.sendTypes.sender ?
          express.senderPhone : this.$store.getters.getUserProfile.user_personal_contact.phone,
      }

      if(this.$store.getters.getIsAdminRights === 'admin'){
        senderData.senderContactName = this.ex.express.user.contacts[0].user_full_name
        senderData.senderPhone = this.ex.express.user.contacts[0].phone
      }

      let products = []

      this.ex.express.products.map(item => {
       if(this._.has(item.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue, 'value')){
         products.push({
           name: item.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue.value.translationStorage.en.name,
           count: item.product.itemsQuantity,
           price: item.product.price,
         })
       }
      })

      let prepareCreationData = this.ex.prepareCreation()

      let data = {}

      switch (deliveryType.id) {
        case DHL_DELIVERY_SERVICE.id:
          data = {
            "warehouse_id" : express.sendType === express.sendTypes.warehouse ? express.warehouse.id : null,
            "sender_contact_name": senderData.senderContactName,
            "sender_phone_number" : senderData.senderPhone,
            "recipient_address" : prepareCreationData.recipient_address,
            "recipient_city" :  prepareCreationData.recipient_city,
            "recipient_region" :  prepareCreationData?.recipient_region,
            "recipient_zip_code" : prepareCreationData.recipient_zip_code,
            "recipient_country_id" : prepareCreationData.recipient_country_id,
            "products" : products,
            "proform_packaging" : prepareCreationData.proform_packaging,
            "delivery_date" : prepareCreationData.delivery_date,
            "insurance_amount" : prepareCreationData.insurance_amount
          }
          break

        case TNT_DELIVERY_SERVICE.id:
          data = {
            "user_id": prepareCreationData.user_id,
            "warehouse_id" : express.sendType === express.sendTypes.warehouse ? express.warehouse.id : null,
            "sender": express.sendType === express.sendTypes.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "city": express.warehouse.sender_city,
                "post_code": express.warehouse.sender_postcode
              } :
              {
                "country_code": express.user?.user_personal_contact ? express.user.user_personal_contact.country.code_iso_2 :
                    express.user.contacts[0].country.code_iso_2,
                "city": express.user?.user_personal_contact ? express.user.user_personal_contact.city :
                    express.user.contacts[0].city,
                "post_code": express.user?.user_personal_contact ? express.user.user_personal_contact.zip :
                    express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": delivery.country.code_iso_2,
              "city": prepareCreationData.recipient_city,
              "post_code": prepareCreationData.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": prepareCreationData.proform_packaging.weight,
              "length": prepareCreationData.proform_packaging['length'],
              "width": prepareCreationData.proform_packaging.width,
              "height": prepareCreationData.proform_packaging.height,
            },
            "insurance_amount": prepareCreationData.insurance_amount
          }
          break

        case FEDEX_DELIVERY_SERVICE.id:
          data = {
            "user_id": prepareCreationData.user_id,
            "warehouse_id" : express.sendType === express.sendTypes.warehouse ? express.warehouse.id : null,
            "sender": express.sendType === express.sendTypes.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "post_code": express.warehouse.sender_postcode
              } : express.user?.use_alternative_address === 1 ?
                {
                  "country_code": express.user?.user_alternative_contact ? express.user.user_alternative_contact.country.code_iso_2 :
                      express.user.contacts[1].country.code_iso_2,
                  "city": express.user?.user_alternative_contact ? express.user.user_alternative_contact.city :
                      express.user.contacts[1].city,
                  "post_code": express.user?.user_alternative_contact ? express.user.user_alternative_contact.zip :
                      express.user.contacts[1].zip
                } :
              {
                "country_code": express.user?.user_personal_contact ? express.user.user_personal_contact.country.code_iso_2 :
                    express.user.contacts[0].country.code_iso_2,
                "city": express.user?.user_personal_contact ? express.user.user_personal_contact.city :
                    express.user.contacts[0].city,
                "post_code": express.user?.user_personal_contact ? express.user.user_personal_contact.zip :
                    express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": delivery.country.code_iso_2,
              "city": prepareCreationData.recipient_city,
              "post_code": prepareCreationData.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": prepareCreationData.proform_packaging.weight,
              "length": prepareCreationData.proform_packaging['length'],
              "width": prepareCreationData.proform_packaging.width,
              "height": prepareCreationData.proform_packaging.height,
              "packaging_type": prepareCreationData.packaging_type,
            },
            "insurance_amount": prepareCreationData.insurance_amount,
          }
          break

        case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id:
          data = {
            "user_id": prepareCreationData.user_id,
            "warehouse_id" : express.sendType === express.sendTypes.warehouse ? express.warehouse.id : null,
            "sender": express.sendType === express.sendTypes.warehouse ? {
                "country_code": express.warehouse.country.code_iso_2,
                "post_code": express.warehouse.sender_postcode
              } : express.user?.use_alternative_address === 1 ?
                {
                  "country_code": express.user?.user_alternative_contact ? express.user.user_alternative_contact.country.code_iso_2 :
                      express.user.contacts[1].country.code_iso_2,
                  "city": express.user?.user_alternative_contact ? express.user.user_alternative_contact.city :
                      express.user.contacts[1].city,
                  "post_code": express.user?.user_alternative_contact ? express.user.user_alternative_contact.zip :
                      express.user.contacts[1].zip
                } :
              {
                "country_code": express.user?.user_personal_contact ? express.user.user_personal_contact.country.code_iso_2 :
                    express.user.contacts[0].country.code_iso_2,
                "city": express.user?.user_personal_contact ? express.user.user_personal_contact.city :
                    express.user.contacts[0].city,
                "post_code": express.user?.user_personal_contact ? express.user.user_personal_contact.zip :
                    express.user.contacts[0].zip
              },
            "recipient": {
              "country_code": delivery.country.code_iso_2,
              "post_code": prepareCreationData.recipient_zip_code
            },
            "products": products,
            "proform_packaging": {
              "weight": prepareCreationData.proform_packaging.weight,
              "length": prepareCreationData.proform_packaging['length'],
              "width": prepareCreationData.proform_packaging.width,
              "height": prepareCreationData.proform_packaging.height,
              "packaging_type": prepareCreationData.packaging_type,
            },
            "insurance_amount": prepareCreationData.insurance_amount,
          }
          break
      }

      if(this.ex.express.typeTransaction !== '' &&
        this.ex.express.typeTransactionId !== ''){
        data['payment_transaction_id'] = this.ex.express.typeTransaction === 'from_order_external' && this.ex.express.paymentTransactionId !== -1 ?
            this.ex.express.paymentTransactionId : this.ex.express.typeTransactionId
      }


      if(this.ex.express.promoCodes.length && this.ex.express.promoCodes[0].data.code !== '') {
        let promocode = this.ex.checkPromoCodePrepareData()
        data['user_id'] = promocode.user_id
        data['delivery_service_id'] = promocode.delivery_service_id
        data['promocodes'] = promocode.promocodes
      }

      data['order_express_id'] = express.expressId

      return data
    },

    // getCostExpressLabelFromCard(data, deliveryType) {
    //   this.$store.dispatch('getShippingCost', {data: data, type: deliveryType}).then((response) => {
    //     if (this._.has(response, 'data') &&
    //       response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
    //
    //       this.labelPrice = response.data.data
    //
    //       this.openNotify('success', 'common_notificationRecordChanged')
    //
    //     } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
    //       let errors = response.response.data.errors
    //
    //       if(this._.has(errors, 'delivery_date')){
    //         this.openNotify('error', {txtServer: errors['delivery_date'][0]})
    //       }
    //
    //       if(this._.has(errors, 'sender_phone_number')){
    //         this.openNotify('error', {txtServer: errors['sender_phone_number'][0]})
    //       }
    //
    //       if(this._.has(errors, 'api_errors')){
    //         this.openNotify('error', {txtServer: errors['api_errors'][0]})
    //       }
    //
    //       // alert('novalid')
    //     } else {
    //       this.openNotify('error', 'common_notificationUndefinedError')
    //     }
    //   })
    // },


    checkValidExpressFiles(){

      if(this.ex.express.typeTransactionId !== '' && this.ex.express.orderPrice !== '')
        return true

      if(this.$store.getters.getExpress.issetPaymentTransaction)
        return true

      if(this.ex.express.type !== DHL_DELIVERY_SERVICE.id)
        return true

      if (this.ex.express.delivery.delivery.country.id === USA_COUNTRY_ID)
        return true

      return this.ex.deliveryFilesValid()
    },

    checkValidVatNumberFile(){
      if(this.ex.express.type === FEDEX_DELIVERY_SERVICE.id)
        return true

      // if(this.ex.express.isVatNumberByCountry)
      //   return this.ex.express.vatNumberFile.firstValidation()

      if(!this.ex.express.visibleVatNumber)
        return true


      return this.ex.express.vatNumberFile.firstValidation()
    },

    nextPageHandler(){
      let valid = true

      switch (this.activeStep) {
        case 1:
          valid = this.ex.firstValid()
          break
        case 2:
          valid = this.ex.express.personal.personalInfoPartsValidate({
              phoneEmpty: true,
              phoneNumber: true,
              personalName: true,
              personalEmail: this.ex.express.type !== FEDEX_DELIVERY_SERVICE.id,
            }) &&
            this.ex.express.delivery.deliveryInfoPartialValidate({
              address: true,
              address2: true,
              city: true,
              region: true,
              country: true,
              zip: true,
              latin: false,
              onlyLatinAddress: true,
            }) &&
            this.validateFranceZip() &&
            // this.ex.express.delivery.deliveryAddressExcludedValidate() &&
            this.ex.deliveryDateValid() &&
            this.ex.freightCostValidate() &&
            this.ex.vatNumberValidation() &&
            this.checkValidVatNumberFile() //&&
            //this.checkValidExpressFiles()
          break
        case 3:
          valid = this.ex.validateOrderPrice() && this.ex.validateProducts()
          this.ex.express.products.map(item => {
            if(!item.productProformValidation({
              proformCategory: true,
              itemsQuantity: true,
              price: true,
              HSCode: this.ex.getVisibleHSCode(),
              proformMaterial: true,
            })){
              valid = false
            }
          })
          break
        case 4:
          valid = this.ex.express.packaging.packagingValidate() &&
            this.ex.insuranceValueValidate()
          break
      }

      if(valid)
        if ( this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    },

    advancedUserValidate() {
      let valid = true

      if (!this.ex.firstValid()) {
        valid = false
      }

      if (!this.ex.express.personal.personalInfoPartsValidate({
            phoneEmpty: true,
            phoneNumber: true,
            personalName: true,
            personalEmail: this.ex.express.type !== FEDEX_DELIVERY_SERVICE.id,
          }) ||
          !this.ex.express.delivery.deliveryInfoPartialValidate({
            address: true,
            address2: true,
            city: true,
            region: true,
            country: true,
            zip: true,
            latin: false,
            onlyLatinAddress: true,
          }) ||
          !this.validateFranceZip() ||
          !this.ex.deliveryDateValid() ||
          !this.ex.freightCostValidate() ||
          !this.ex.vatNumberValidation() ||
          !this.checkValidVatNumberFile() //||
          //!this.checkValidExpressFiles()
      ) {
        valid = false
      }

      if (!this.ex.validateOrderPrice() || !this.ex.validateProducts()) {
        valid = false
      }

      this.ex.express.products.map(item => {
        if(!item.productProformValidation({
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          HSCode: this.ex.getVisibleHSCode(),
          proformMaterial: true,
        })){
          valid = false
        }
      })

      if (!this.ex.express.packaging.packagingValidate() ||
          !this.ex.insuranceValueValidate()) {
        valid = false
      }

      return valid
    },

    saveInDraft(edit) {
      this.closeSaveInDraftsPopup()
      let type = edit ? 'adminSave' : 'createExpress'
      this.$emit(type, {draft: true})
    },

    changeUser(user) {

      this.ex.setSenderInfo(user ? user : this.loadUserAuthorizedData.user)

      this.ex.setExpressUser(user)
      this.ex.express.products.map((itemProduct) => {
        itemProduct.setProductUser(user)
        this.changeCategoryProform({
          val: '',
          nameVal: '',
          item: itemProduct
        })
      })
    },

    changeHasCreateLabel(val) {
      this.hasCreateLabel = val
    },

    createFiles(expressId) {
      return new Promise((resolve, reject) => {

        if(this.ex.express.files.length === 0){
          this.createVatNumberFile(expressId).then(response => {
            console.log(response);
            resolve('complete')
          })
          return
        }

        let reader = new FormData();
        this.ex.express.files.map((item) => {
          if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
            reader.append("file", item.file);
          }
        })
        reader.append("order_express_id", expressId);

        this.$store.dispatch('addPhotoToExpress', reader).then((response) => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            // this.openNotify('success', 'common_notificationRecordCreated')

            if(this.ex.express.vatNumberFile.getFiles().length > 0){
              this.createVatNumberFile(expressId).then(response => {
                console.log(response);
                resolve('complete')
              })
            } else {
              resolve('complete')
            }
          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            alert('novalid')
            reject('error')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
            reject('error')
          }

        })
      })
    },

    createVatNumberFile(expressId) {
      let reader = new FormData();

      this.ex.express.vatNumberFile.getFiles().map((item) => {
        if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
          reader.append(`file`, item.file);
        }
      })
      reader.append("order_express_id", expressId);
      return this.$store.dispatch('addVatNumberFileExpress', reader).then((response) => {
        return response
      })
    },

    afterPay() {

      this.$store.dispatch('afterInvoicePayStatusUpdate', this.$route.params.id)
      this.braintreePaymentPopup = false

      // this.changeThankYouPopupPopup(true)
    },

    makeLabelHandler(id, expressType) {
      return new Promise((resolve) => {

        let dataPayload = {
          order_express_id: id
        }

        if(FEDEX_DELIVERY_SERVICE.id === expressType.id || NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id === expressType.id) {
          dataPayload['delivery_service_type'] = this.ex.express.variantLabelPrice
        }

        this.$store.dispatch('makeLabel', {type: expressType.name, dataPayload}).then(response => {
          if (this._.has(response, 'data') &&
            response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            let responseData = response.data.data
            if(responseData.status === EXPRESS_MAKE_LABEL_STATUSES.NOT_PAYED){
              this.openNotify('error', {txtServer: responseData.message})



              this.authorizePaySum = responseData.total_amount
              this.authorizeInvoiceId = responseData.invoice_id

              this.authorizePayData = {
                userBalance: responseData.userBalance,
                orderAmount: responseData.order_amount,
                paySystemCommission: responseData.payment_system_commission,
              }
              console.log(this.authorizePayData);
              console.log(345345);

              if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
                this.braintreePaymentPopup = true
              } else {
                this.changeAuthorizePaymentPopup(true)
              }

              resolve({statusPaid: EXPRESS_MAKE_LABEL_STATUSES.NOT_PAYED})
              return
            }

            resolve('complete')

          } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors

            if(this._.has(errors, 'api_errors')){
              this.openNotify('error', {txtServer: errors['api_errors'][0]})
              resolve('error')
              return
            }

            if(Object.keys(errors).length > 0){
              this.openNotify('error', {txtServer: this._.first(errors[this._.first(Object.keys(errors))]) })
            }

            resolve({statusError: 'error'})
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
            resolve('error')
          }
        })
      })

    },

  },
}

export const expressMixinDeliveryDetails = {

  mixins: [expressUserContactsMixin],

  // watch: {
  //   hideCostProofs() {
  //     return false
  //   },
  // },

  methods: {

    changeCountry(val) {
      if (val === '') {
        this.ex.express.delivery.setCountry('')
        return
      }
      this.ex.express.delivery.setCountry(val)
      this.ex.express.delivery.setRegion('')
    },

    changeRegion(val) {
      this.ex.express.delivery.setRegion(val?.code)
    },

    openGetMNContactDataPopup() {
      let text = {
        title: 'common_askSavedAddress',
        txt: '',
        yes: 'common_yes',
        no: 'common_no'
      }

      let confirm = () => {

        this.$store.dispatch('getWarehouses', BLAINE_WAREHOUSE.id).then((response) => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              let data = this.getRespData(response)
              if (!this.ex.express.delivery.delivery.country?.id || this.ex.express.delivery.delivery.country?.id !== USA_COUNTRY_ID) {
                let countries = this.options || this.$store.getters.getCountries
                this.changeCountry(countries.find(item => {
                  return item.id === data?.sender_contact?.country_id
                }))

                //создаем вотчер который мы потом можем отключить, чтобы подождать получение списка регионов
                if (data?.sender_contact?.country_id === USA_COUNTRY_ID) {
                  let regionsWatcher = this.$watch('ex.express.regions', () => {
                    this.changeRegion(this.ex.getRegions().find(regionItem => {
                      return regionItem.id === data?.sender_contact?.country_region_id
                    }))
                    regionsWatcher()
                  })
                }
              }

              this.ex.express.delivery.delivery.zip = data?.sender_contact?.zip
              this.ex.express.delivery.delivery.city = data?.sender_contact?.city
              this.ex.express.delivery.delivery.address = data?.sender_contact?.address

              console.log(data);
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    checkCurrencies(newVal, edit = false){
      if(newVal && newVal.id === USA_COUNTRY_ID){
        this.optionsCurrencies = [
          CURRENCIES.USD,
        ]
      } else if(newVal && newVal.id === UNITED_KINGDOM_ID && this.ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id) {
        this.optionsCurrencies = [
          CURRENCIES.GBP,
        ]
        this.changeCurrencies(this.optionsCurrencies[0])
      }
      // else if(newVal && newVal.id === UNITED_KINGDOM_ID) {
      //   this.optionsCurrencies = [
      //     CURRENCIES.GBP,
      //   ]
      // }
      else {
        this.optionsCurrencies = [
          CURRENCIES.USD,
          CURRENCIES.EUR,
          CURRENCIES.GBP,
        ]
      }

      if(!edit) {
        if(!this._.find(this.optionsCurrencies, {id: this.ex.express.currencies?.id})) {
          this.changeCurrencies('')
        }
        this.initCurrencies()
      }
    },

    getRangeDeliveryDate(date) {

      // warehouse?.id === 11 - riga
      // warehouse?.id === 13 - lodz
      // warehouse?.id === 16 - cambridge
      if(this.ex.express.type === FEDEX_DELIVERY_SERVICE.id &&
        (this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.getUserContactsBySelectedUser?.country?.id === UKRAINIAN_COUNTRY_ID ||
          this.ex.express.sendType === this.ex.express.sendTypes.sender && this.getUserContactsBySelectedUser?.country?.id === UKRAINIAN_COUNTRY_ID)
        // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 11) &&
        // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 16) &&
        // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 13)
      ){
        const day = new Date(date).getDay()
        return (
          date < new Date().setDate(new Date().getDate() + 3) &&
          date > new Date().setDate(new Date().getDate() - 1000)
        ) || day === 6
      }
      return false
    },

  },

}
